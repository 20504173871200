export default function debounce(func, timeout = 300) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    if (window && typeof window !== "undefined") {
      timer = window.setTimeout(() => {
        func.apply(this, args)
      }, timeout)
    }
  }
}
