import * as React from "react"

import { debounce } from "../utils"

export const ThemeContext = React.createContext({ device: "2xs" })

export const ThemeProvider = props => {
  const [value, setValue] = React.useState({
    device: "2xs",
  })
  //const [height, setHeight] = React.useState(null)

  React.useEffect(() => {
    var prevHeight
    var prevWidth

    const getScreenSize = () => {
      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth

      let newVal
      if (width <= 640) {
        newVal = {
          ...value,
          device: "sm",
        }
      } else if (width <= 768) {
        newVal = {
          ...value,
          device: "md",
        }
      } else if (width <= 1024) {
        newVal = {
          ...value,
          device: "lg",
        }
      } else if (width <= 1280) {
        newVal = {
          ...value,
          device: "xl",
        }
      } else if (width <= 1536) {
        newVal = {
          ...value,
          device: "2xl",
        }
      }
      if (
        prevHeight === width &&
        prevWidth === height &&
        height !== undefined
      ) {
        console.log("orientation change")
      } else {
        setValue({ ...newVal })
        console.log("resize ")
      }

      prevHeight = height
      prevWidth = width
    }

    if (window && typeof window !== "undefined" && document) {
      getScreenSize()
      window.addEventListener("resize", debounce(getScreenSize))
    }
  }, [])

  return (
    <ThemeContext.Provider value={value}>
      {props.children}
    </ThemeContext.Provider>
  )
}
