import debounce from "./debounce"
import { SiCloudflare, SiReact, SiTailwindcss, SiCss3, SiMongodb, SiPandas } from "react-icons/si"
import { RiGatsbyFill } from "react-icons/ri" 
import { AiOutlineHtml5 } from "react-icons/ai"
import { IoLogoPython, IoLogoNodejs } from "react-icons/io5"

const ICON_MAPPING = {
    "gatsby": RiGatsbyFill,
    "css3": SiCss3,
    "html5": AiOutlineHtml5,
    "cf": SiCloudflare,
    "react": SiReact,
    "tw": SiTailwindcss,
    "mongo": SiMongodb,
    "python": IoLogoPython,
    "node": IoLogoNodejs,
    "pandas": SiPandas
}

const ICON_NAME_MAPPING = {
    "gatsby": "Gatsby",
    "css3": "CSS 3",
    "html5": "HTML 5",
    "cf": "Cloudflare",
    "react": "React",
    "tw": "Tailwind CSS",
    "mongo": "Mongo DB",
    "python": "Python",
    "node": "Node.js",
    "pandas": "Pandas"
}

export { debounce, ICON_MAPPING, ICON_NAME_MAPPING }
